import React, { useEffect, useCallback, useState, useRef } from "react";
import {
  Box,
  VStack,
  Text,
  Button,
  IconButton,
  HStack,
  Input,
  Spinner,
  useToast,
} from "@chakra-ui/react";
import {
  ChevronDownIcon,
  ChevronRightIcon,
  ChevronUpIcon,
  ArrowUpIcon,
  ArrowDownIcon,
  RepeatIcon,
} from "@chakra-ui/icons";
import { FaDatabase, FaCube, FaLayerGroup, FaSitemap } from "react-icons/fa6";
import {
  ContainerStructure,
  ComponentInstance,
  ComponentBlueprint,
} from "./ViewgraphEditor";

interface ComponentTreeProps {
  structure: ContainerStructure;
  selectedComponentIds: string[];
  setSelectedComponentIds: (ids: string[]) => void;
  selectedContainerId: string | null;
  onSelectComponent: (componentId: string) => void;
  onSelectContainer: (containerId: string) => void;
  onMoveComponent: (
    containerId: string,
    fromIndex: number,
    toIndex: number
  ) => void;
  availableComponentBlueprints: ComponentBlueprint[];
  onToggleRootLayoutDirection: () => void;
  onDeleteComponent: (componentId: string) => void;
  onDuplicateComponent: (componentId: string) => void;
}

const ComponentTree: React.FC<ComponentTreeProps> = ({
  structure,
  selectedComponentIds,
  setSelectedComponentIds,
  selectedContainerId,
  onSelectComponent,
  onSelectContainer,
  onMoveComponent,
  availableComponentBlueprints,
  onToggleRootLayoutDirection,
  onDeleteComponent,
  onDuplicateComponent,
}) => {
  const [expanded, setExpanded] = useState<Record<string, boolean>>({
    [structure.id]: true,
  });
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState<ComponentInstance[]>([]);
  const [isSearchActive, setIsSearchActive] = useState(false);
  const searchInputRef = useRef<HTMLInputElement>(null);
  const toast = useToast();

  const isDataModule = (name: string) =>
    availableComponentBlueprints.find((bp) => bp.name === name)?.isModule ||
    false;

  const toggleExpand = (id: string) => {
    setExpanded((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  const expandAll = (
    container: ContainerStructure,
    expandState: Record<string, boolean>
  ): Record<string, boolean> => {
    expandState[container.id] = true;
    container.subcontainers.forEach((sub) => expandAll(sub, expandState));
    return expandState;
  };

  const handleComponentClick = (e: React.MouseEvent, id: string) => {
    e.stopPropagation();
    setSelectedComponentIds((prev) =>
      e.shiftKey
        ? prev.includes(id)
          ? prev.filter((pid) => pid !== id)
          : [...prev, id]
        : [id]
    );
  };

  const moveComponent = (componentId: string, direction: number) => {
    const container = findContainer(structure, selectedContainerId);
    if (!container) return;
    const index = container.components.findIndex((c) => c.id === componentId);
    const newIndex = index + direction;
    if (newIndex >= 0 && newIndex < container.components.length) {
      onMoveComponent(container.id, index, newIndex);
    }
  };

  const findContainer = (
    container: ContainerStructure,
    id: string
  ): ContainerStructure | null => {
    if (container.id === id) return container;
    for (const sub of container.subcontainers) {
      const found = findContainer(sub, id);
      if (found) return found;
    }
    return null;
  };

  const flattenComponentTree = useCallback(
    (container: ContainerStructure): ComponentInstance[] =>
      container.components.concat(
        ...container.subcontainers.map(flattenComponentTree)
      ),
    []
  );

  const selectNextComponent = useCallback(
    (dir: "up" | "down") => {
      const flat = flattenComponentTree(structure);
      const idx = flat.findIndex((c) => c.id === selectedComponentIds[0]);
      const next = dir === "up" ? idx - 1 : idx + 1;
      if (next >= 0 && next < flat.length) onSelectComponent(flat[next].id);
    },
    [structure, selectedComponentIds, onSelectComponent, flattenComponentTree]
  );

  const handleSearch = (term: string) => {
    setSearchTerm(term);
    setSearchResults(
      term
        ? flattenComponentTree(structure).filter((c) =>
            c.blueprintName.toLowerCase().includes(term.toLowerCase())
          )
        : []
    );
  };

  const highlight = (text: string, term: string) =>
    text.split(new RegExp(`(${term})`, "gi")).map((part, i) =>
      part.toLowerCase() === term.toLowerCase() ? (
        <Text as="mark" key={i} bg="yellow.200">
          {part}
        </Text>
      ) : (
        part
      )
    );

  const handleKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (e.metaKey && e.key === "f") {
        e.preventDefault();
        setIsSearchActive(true);
        setTimeout(() => searchInputRef.current?.focus(), 100);
      } else if (e.key === "Escape") {
        setIsSearchActive(false);
        setSearchTerm("");
        setSearchResults([]);
      } else if (e.metaKey && e.key === "Backspace") {
        e.preventDefault();
        selectedComponentIds.forEach(onDeleteComponent);
        toast({
          title: "Components deleted",
          status: "success",
          duration: 2000,
          isClosable: true,
        });
      } else if (e.metaKey && e.shiftKey) {
        if (e.key === "ArrowUp") moveComponent(selectedComponentIds[0], -1);
        if (e.key === "ArrowDown") moveComponent(selectedComponentIds[0], 1);
      } else if (e.metaKey && e.ctrlKey) {
        if (e.key === "ArrowUp") selectNextComponent("up");
        if (e.key === "ArrowDown") selectNextComponent("down");
      }
    },
    [
      selectedComponentIds,
      onDeleteComponent,
      moveComponent,
      selectNextComponent,
      toast,
    ]
  );

  useEffect(() => {
    expandAll(structure, expanded);
    setExpanded(expanded);
  }, [structure]);

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [handleKeyDown]);

  const ComponentItem: React.FC<{
    component: ComponentInstance;
    depth: number;
    index: number;
    containerId: string;
    total: number;
    parent?: ContainerStructure;
    parentIndex?: number;
  }> = ({
    component,
    depth,
    index,
    containerId,
    total,
    parent,
    parentIndex,
  }) => (
    <Box
      key={component.id}
      pl={depth * 4}
      _hover={{ bg: "gray.50" }}
      position="relative"
    >
      <HStack spacing={2} width="100%">
        <Button
          variant="ghost"
          justifyContent="flex-start"
          width="100%"
          onClick={(e) => handleComponentClick(e, component.id)}
          bg={
            selectedComponentIds.includes(component.id)
              ? "blue.200"
              : "transparent"
          }
          _hover={{ bg: "gray.100" }}
          size="sm"
        >
          {isDataModule(component.blueprintName) ? (
            <FaDatabase size="0.8em" style={{ marginRight: "0.5em" }} />
          ) : (
            <FaCube size="0.8em" style={{ marginRight: "0.5em" }} />
          )}
          <Text fontSize="xs">{component.blueprintName}</Text>
        </Button>
        <HStack
          position="absolute"
          right={2}
          opacity={0}
          transition="opacity 0.2s"
          _hover={{ opacity: 1 }}
        >
          <IconButton
            aria-label="Move up"
            icon={index === 0 && parent ? <ArrowUpIcon /> : <ChevronUpIcon />}
            size="xs"
            onClick={(e) => {
              e.stopPropagation();
              if (index === 0 && parent && parentIndex !== undefined) {
                onMoveComponent(parent.id, parentIndex, parentIndex - 1);
              } else {
                onMoveComponent(containerId, index, index - 1);
              }
            }}
            isDisabled={index === 0 && !parent}
            _hover={{ bg: "gray.200" }}
          />
          <IconButton
            aria-label="Move down"
            icon={
              index === total - 1 && parent ? (
                <ArrowDownIcon />
              ) : (
                <ChevronDownIcon />
              )
            }
            size="xs"
            onClick={(e) => {
              e.stopPropagation();
              if (index === total - 1 && parent && parentIndex !== undefined) {
                onMoveComponent(parent.id, parentIndex, parentIndex + 1);
              } else {
                onMoveComponent(containerId, index, index + 1);
              }
            }}
            isDisabled={index === total - 1 && !parent}
            _hover={{ bg: "gray.200" }}
          />
        </HStack>
      </HStack>
    </Box>
  );

  const ContainerItem: React.FC<{
    container: ContainerStructure;
    depth: number;
    parent?: ContainerStructure;
    parentIndex?: number;
  }> = ({ container, depth, parent, parentIndex }) => (
    <Box key={container.id} mb={1}>
      <HStack spacing={0} p={1} borderRadius="md">
        <IconButton
          aria-label={expanded[container.id] ? "Collapse" : "Expand"}
          icon={
            expanded[container.id] ? <ChevronDownIcon /> : <ChevronRightIcon />
          }
          onClick={(e) => {
            e.stopPropagation();
            toggleExpand(container.id);
          }}
          variant="ghost"
          size="xs"
        />
        <Button
          variant="ghost"
          justifyContent="flex-start"
          width="100%"
          onClick={(e) => {
            e.stopPropagation();
            onSelectContainer(container.id);
          }}
          bg={selectedContainerId === container.id ? "blue.100" : "transparent"}
          size="sm"
          position="relative"
        >
          {depth === 0 ? (
            <FaSitemap size="0.8em" style={{ marginRight: "0.5em" }} />
          ) : (
            <FaLayerGroup size="0.8em" style={{ marginRight: "0.5em" }} />
          )}
          <Text fontSize="sm" mr={6} fontWeight="bold">
            {container.id === "root"
              ? "Root Container"
              : `Container ${container.id}`}
          </Text>
          {container.id === "root" && (
            <IconButton
              aria-label="Toggle Layout"
              icon={<RepeatIcon />}
              size="xs"
              onClick={(e) => {
                e.stopPropagation();
                onToggleRootLayoutDirection();
              }}
              _hover={{ bg: "gray.200" }}
              position="absolute"
              right="1"
              top="50%"
              transform="translateY(-50%)"
            />
          )}
        </Button>
      </HStack>
      {expanded[container.id] && (
        <VStack align="stretch" pl={3} pt={1}>
          {container.components.map((comp, idx) => (
            <ComponentItem
              key={comp.id}
              component={comp}
              depth={depth + 1}
              index={idx}
              containerId={container.id}
              total={container.components.length}
              parent={parent}
              parentIndex={parentIndex}
            />
          ))}
          {container.subcontainers.map((sub, idx) => (
            <ContainerItem
              key={sub.id}
              container={sub}
              depth={depth + 1}
              parent={container}
              parentIndex={idx}
            />
          ))}
        </VStack>
      )}
    </Box>
  );

  const handleExpandCollapse = (expand: boolean) => {
    setExpanded(expandAll(structure, {}));
    if (!expand) setExpanded({ [structure.id]: true });
  };

  return (
    <VStack
      align="stretch"
      spacing={0.5}
      p={3}
      bg="white"
      borderRadius="sm"
      height="100%"
      fontSize="xs"
    >
      <HStack spacing={1} p={1}>
        <Button size="xs" onClick={() => handleExpandCollapse(true)}>
          Expand All
        </Button>
        <Button size="xs" onClick={() => handleExpandCollapse(false)}>
          Collapse All
        </Button>
      </HStack>
      {isSearchActive && (
        <Box p={1}>
          <Input
            ref={searchInputRef}
            value={searchTerm}
            onChange={(e) => handleSearch(e.target.value)}
            placeholder="Search..."
            size="xs"
            borderColor="gray.300"
          />
          {searchResults.length > 0 && (
            <Box
              mt={1}
              border="1px solid"
              borderColor="gray.300"
              borderRadius="md"
              maxH="150px"
              overflowY="auto"
            >
              {searchResults.map((comp) => (
                <Box
                  key={comp.id}
                  p={1}
                  cursor="pointer"
                  _hover={{ bg: "gray.100" }}
                  onClick={() => {
                    onSelectComponent(comp.id);
                    setIsSearchActive(false);
                    setSearchTerm("");
                    setSearchResults([]);
                  }}
                >
                  {highlight(comp.blueprintName, searchTerm)}
                </Box>
              ))}
            </Box>
          )}
        </Box>
      )}
      <ContainerItem container={structure} depth={0} />
    </VStack>
  );
};

export default ComponentTree;
