import React, { useCallback, useState, useRef, useEffect } from "react";
import {
  Button,
  useDisclosure,
  Box,
  ButtonGroup,
  Text,
  IconButton,
  Input,
  Heading,
  VStack,
  Select,
  Switch,
  Icon,
  HStack,
  Spinner,
  useToast,
} from "@chakra-ui/react";

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Divider,
} from "@chakra-ui/react";

import { useParams } from "react-router-dom";

import { useAppDescriptorStore } from "@/bundles/DescriptorEditor/stores/appDescriptorStore";

import {
  ComponentBlueprint,
  ComponentInstance,
  ContainerStructure,
  Viewgraph,
} from "./ViewgraphEditor";

import ViewgraphComponentInstance from "./ViewgraphComponentInstance";

import ComponentPropertiesPanel from "./ComponentPropertiesPanel";

import ComponentLayoutEditor from "./ComponentLayoutEditor";

import {
  DeleteIcon,
  ChevronUpIcon,
  ChevronDownIcon,
  EditIcon,
} from "@chakra-ui/icons";

import { get } from "lodash";

import ComponentTree from "./ComponentTree";

import LayoutModeEditor from "./LayoutModeEditor";

import ComponentLibraryModal from "./ComponentLibraryModal";
import { FiDivide, FiPlus } from "react-icons/fi";

interface ComponentModeEditorProps {
  keypath: string;

  availableComponentBlueprints: ComponentBlueprint[];

  isPreviewMode: boolean;

  setIsPreviewMode: (isPreview: boolean) => void;

  selectedComponentIds: string[];

  setSelectedComponentIds: (ids: string[]) => void;

  structure: ContainerStructure | null;
}

interface TypedValue {
  type: string;

  value: any;
}

const ComponentModeEditor: React.FC<ComponentModeEditorProps> = ({
  keypath,

  availableComponentBlueprints,

  isPreviewMode,

  setIsPreviewMode,

  selectedComponentIds,

  setSelectedComponentIds,

  structure,
}) => {
  const { projectId, pageId } = useParams<{
    projectId: string;

    pageId: string;
  }>();

  const {
    getFragment,

    setFragment,

    addRecordToCollectionFragment,

    removeRecordFromCollectionFragment,

    getPage,

    isLoading,
  } = useAppDescriptorStore();

  const [blueprintNameBuffer, setBlueprintNameBuffer] = useState<string | null>(
    null
  );

  const toast = useToast(); // Move useToast inside the component

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!pageId) {
    return <div>Page not found</div>;
  }

  // Fetch the page path from the app descriptor

  const cleanPageId = pageId.replace(/^id:/, "");

  const page = getPage(cleanPageId);

  console.log("page", page);

  if (!page) {
    return <div>Page not found</div>;
  }

  const viewgraph = getFragment(keypath) as Viewgraph;
  if (!viewgraph) {
    console.error(`Viewgraph not found at keypath: ${keypath}`);
    return null; // or a loading state
  }

  const [showBorders, setShowBorders] = useState(true);

  const [draggedOver, setDraggedOver] = useState<string | null>(null);

  const [selectedContainerKeypath, setSelectedContainerKeypath] = useState<
    string | null
  >(null);

  const [editorMode, setEditorMode] = useState<
    "properties" | "layout" | "style"
  >("properties");

  const editorRef = useRef<HTMLDivElement>(null);

  const [showNoComponentsModal, setShowNoComponentsModal] = useState(false);

  const [isLayoutModalOpen, setIsLayoutModalOpen] = useState(false);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [selectedContainerId, setSelectedContainerId] = useState<string | null>(
    null
  );

  const handleAddComponent = (blueprintName: string) => {
    if (!selectedContainerId) return;

    const newComponentInstance: ComponentInstance = {
      id: generateUniqueId(),
      blueprintName: blueprintName,
      propertiesBindings: {},
      reactions: {},
    };

    const containerKeypath = findContainerKeypathById(
      viewgraph.containerStructure,
      selectedContainerId,
      `${keypath}/containerStructure`
    );
    if (containerKeypath) {
      addRecordToCollectionFragment(
        `${containerKeypath}/components`,
        newComponentInstance
      );
    }
    onClose();
  };

  const handleMoveComponentInTree = useCallback(
    (containerId: string, fromIndex: number, toIndex: number) => {
      const containerKeypath = findContainerKeypathById(
        viewgraph.containerStructure,
        containerId,
        `${keypath}/containerStructure`
      );
      if (containerKeypath) {
        const container = getFragment(containerKeypath) as ContainerStructure;
        if (container.components) {
          const newComponents = [...container.components];
          const [movedComponent] = newComponents.splice(fromIndex, 1);
          if (toIndex < 0) {
            // Move to previous container
            const parentContainerKeypath = findParentContainerKeypath(
              viewgraph.containerStructure,
              containerId,
              `${keypath}/containerStructure`
            );
            if (parentContainerKeypath) {
              const parentContainer = getFragment(
                parentContainerKeypath
              ) as ContainerStructure;
              parentContainer.components.splice(
                parentContainer.components.length,
                0,
                movedComponent
              );
              setFragment(
                `${parentContainerKeypath}/components`,
                parentContainer.components
              );
            }
          } else if (toIndex >= container.components.length) {
            // Move to next container
            const parentContainerKeypath = findParentContainerKeypath(
              viewgraph.containerStructure,
              containerId,
              `${keypath}/containerStructure`
            );
            if (parentContainerKeypath) {
              const parentContainer = getFragment(
                parentContainerKeypath
              ) as ContainerStructure;
              parentContainer.components.splice(0, 0, movedComponent);
              setFragment(
                `${parentContainerKeypath}/components`,
                parentContainer.components
              );
            }
          } else {
            newComponents.splice(toIndex, 0, movedComponent);
            setFragment(`${containerKeypath}/components`, newComponents);
          }
        }
      }
    },
    [viewgraph, keypath, getFragment, setFragment]
  );

  const findParentContainerKeypath = (
    container: ContainerStructure,
    containerId: string,
    currentKeypath: string
  ): string | null => {
    for (const subcontainer of container.subcontainers) {
      if (subcontainer.id === containerId) {
        return currentKeypath;
      }
      const result = findParentContainerKeypath(
        subcontainer,
        containerId,
        `${currentKeypath}/subcontainers/id:${subcontainer.id}`
      );
      if (result) return result;
    }
    return null;
  };

  const initializePropertiesBindings = (
    blueprint: ComponentBlueprint
  ): Record<string, any> => {
    const bindings: Record<string, any> = {};
    blueprint.properties.forEach((property: any) => {
      bindings[property.name] = {
        directiveType: "literalValue",
        config: {
          type: property.dataType,
          value: getDefaultValueForType(property.dataType),
        },
      };
    });
    return bindings;
  };

  const generateChildContainerName = (
    parentName: string,
    index: number
  ): string => {
    return `subContainer ${parentName}-${index}`;
  };

  const splitContainer = (containerKeypath: string) => {
    const container = getFragment(containerKeypath) as ContainerStructure;
    if (!container) return;

    toast({
      title: "Splitting container...",
      description: <Spinner size="sm" />,
      status: "info",
      duration: 1500,
      isClosable: true,
    });

    const parentName = container.name || "Container";

    const newContainer1: ContainerStructure = {
      id: "sub-" + generateUniqueId(),
      name: generateChildContainerName(parentName, 1),
      layoutDirection: container.layoutDirection,
      size: { value: 1, unit: "fr" },
      subcontainers: [],
      isScrollable: container.isScrollable,
      components: container.components,
      isLayoutApplied: true,
    };

    const newContainer2: ContainerStructure = {
      id: "sub-" + generateUniqueId(),
      name: generateChildContainerName(parentName, 2),
      layoutDirection: container.layoutDirection,
      size: { value: 1, unit: "fr" },
      subcontainers: [],
      isScrollable: container.isScrollable,
      components: [],
      isLayoutApplied: true,
    };

    const updatedContainer: ContainerStructure = {
      ...container,
      name: parentName,
      components: [],
      subcontainers: [newContainer1, newContainer2],
    };

    setFragment(containerKeypath, updatedContainer);

    setTimeout(() => {
      window.location.reload();
    }, 1250);
  };

  const renderContainerProperties = () => {
    if (!selectedContainerKeypath) return null;

    const container = getFragment(
      selectedContainerKeypath
    ) as ContainerStructure;

    if (!container) return null;

    return (
      <div className="p-4">
        <Heading size="md">Container Properties</Heading>
        <VStack spacing={4} align="stretch" mt={4}>
          <Box>
            <Text fontWeight="bold" mb={1}>
              Container Name
            </Text>
            <Input
              value={container?.name || ""}
              onChange={(e) => {
                setFragment(`${selectedContainerKeypath}/name`, e.target.value);
              }}
              placeholder="Enter container name"
            />
          </Box>
          <Box>
            <Text fontWeight="bold" mb={1}>
              Layout Direction
            </Text>
            <Select
              value={container.layoutDirection}
              onChange={(e) => {
                setFragment(
                  `${selectedContainerKeypath}/layoutDirection`,
                  e.target.value
                );
              }}
            >
              <option value="horizontal">Horizontal</option>
              <option value="vertical">Vertical</option>
            </Select>
          </Box>
          <Box>
            <Text fontWeight="bold" mb={1}>
              Is Scrollable
            </Text>
            <Switch
              isChecked={container.isScrollable}
              onChange={(e) => {
                setFragment(
                  `${selectedContainerKeypath}/isScrollable`,
                  e.target.checked
                );
              }}
            />
          </Box>
          <Box>
            <Button
              leftIcon={<Icon as={FiDivide} />}
              size="md"
              colorScheme="blue"
              width="100%"
              mt={2}
              onClick={() => {
                splitContainer(selectedContainerKeypath);
              }}
            >
              Split Container
            </Button>
          </Box>
        </VStack>
      </div>
    );
  };

  const getDefaultValueForType = (dataType: string): any => {
    switch (dataType) {
      case "_types.String":
        return "";
      case "_types.Number":
        return 0;
      case "_types.Boolean":
        return false;
      case "_types.List":
        return [];
      case "_types.Dictionary":
        return {};
      default:
        return null;
    }
  };

  const hasComponents = (container: ContainerStructure): boolean => {
    if (container.components && container.components.length > 0) {
      return true;
    }
    for (const subcontainer of container.subcontainers) {
      if (hasComponents(subcontainer)) {
        return true;
      }
    }
    return false;
  };

  const handlePreviewClick = () => {
    if (!hasComponents(viewgraph.containerStructure)) {
      setShowNoComponentsModal(true);
    } else {
      setIsPreviewMode(true);
    }
  };

  const NoComponentsModal = () => (
    <Modal
      isOpen={showNoComponentsModal}
      onClose={() => setShowNoComponentsModal(false)}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>No Components!</ModalHeader>
        <ModalBody>
          There are no components on the canvas. Are you sure you want to enter
          preview mode?
        </ModalBody>
        <ModalFooter>
          <Button
            variant="ghost"
            onClick={() => {
              setShowNoComponentsModal(false);
              setIsPreviewMode(true);
            }}
          >
            Bypass
          </Button>
          <Button
            colorScheme="blue"
            mr={3}
            onClick={() => setShowNoComponentsModal(false)}
          >
            No
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        editorRef.current &&
        !editorRef.current.contains(event.target as Node)
      ) {
        // Check if the click is on a modal element

        const target = event.target as HTMLElement;

        const isModalClick = target.closest(".chakra-modal__content") !== null;

        if (!isModalClick) {
          setSelectedComponentIds([]);
        }
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const getSizeStyle = (
    parentDirection: "horizontal" | "vertical",
    size?: { value: number; unit: "px" | "fr" }
  ): React.CSSProperties => {
    if (!size) return {};

    const dimension = parentDirection === "horizontal" ? "width" : "height";
    const flexProperty =
      parentDirection === "horizontal" ? "flexBasis" : "flexBasis";

    if (size.unit === "px") {
      return {
        [dimension]: `${size.value}px`,
        [flexProperty]: `${size.value}px`,
        flexGrow: 0,
        flexShrink: 0,
      };
    } else {
      return {
        [flexProperty]: `${size.value}fr`,
        flexGrow: size.value,
        flexShrink: 1,
      };
    }
  };

  const handleDrop = useCallback(
    (
      containerKeypath: string,

      componentId: string | null,

      blueprint?: ComponentBlueprint
    ) => {
      if (componentId) {
        const oldContainerKeypath = findContainerKeypathForComponent(
          viewgraph.containerStructure,

          componentId,

          `${keypath}/containerStructure`
        );

        if (oldContainerKeypath && oldContainerKeypath !== containerKeypath) {
          const existingComponent = getFragment(
            `${oldContainerKeypath}/components/id:${componentId}`
          );

          removeRecordFromCollectionFragment(
            `${oldContainerKeypath}/components`,

            componentId
          );

          addRecordToCollectionFragment(
            `${containerKeypath}/components`,

            existingComponent
          );
        }
      } else if (blueprint) {
        const newComponentInstance: ComponentInstance = {
          blueprintName: blueprint.name,

          propertiesBindings: initializePropertiesBindings(blueprint),

          reactions: {},
        };

        console.log(
          "adding newComponentInstance",

          newComponentInstance,

          containerKeypath
        );

        addRecordToCollectionFragment(
          `${containerKeypath}/components`,

          newComponentInstance
        );
      }
    },

    [
      keypath,

      viewgraph,

      getFragment,

      setFragment,

      addRecordToCollectionFragment,

      removeRecordFromCollectionFragment,
    ]
  );

  const convertTypedValueIntoLiteralValueDirective = (
    typedValue: TypedValue
  ): any => {
    return {
      directiveType: "literalValue",

      config: {
        type: typedValue.type,

        value: typedValue.value,
      },
    };
  };

  const handleComponentSelect = (
    componentId: string,

    containerKeypath: string
  ) => {
    setSelectedComponentIds([componentId]);

    setSelectedContainerKeypath(null);

    setEditorMode("properties"); // Set the editor mode to "properties" when a new component is selected
  };

  const moveComponent = useCallback(
    (containerKeypath: string, fromIndex: number, toIndex: number) => {
      const container = getFragment(containerKeypath) as ContainerStructure;

      if (
        fromIndex >= 0 &&
        fromIndex < container.components.length &&
        toIndex >= 0 &&
        toIndex < container.components.length
      ) {
        const newComponents = [...container.components];

        const [movedComponent] = newComponents.splice(fromIndex, 1);

        newComponents.splice(toIndex, 0, movedComponent);

        setFragment(`${containerKeypath}/components`, newComponents);
      }
    },

    [getFragment, setFragment]
  );

  const moveComponentUp = useCallback(
    (containerKeypath: string, componentIndex: number) => {
      moveComponent(containerKeypath, componentIndex, componentIndex - 1);
    },

    [moveComponent]
  );

  const moveComponentDown = useCallback(
    (containerKeypath: string, componentIndex: number) => {
      moveComponent(containerKeypath, componentIndex, componentIndex + 1);
    },

    [moveComponent]
  );

  const renderContainer = (
    containerKeypath: string,
    parentDirection: "horizontal" | "vertical"
  ) => {
    const container = getFragment(containerKeypath) as ContainerStructure;
    const isRootContainer =
      containerKeypath === `${keypath}/containerStructure`;

    console.log(
      "Rendering container:",
      container.id,
      "with direction:",
      container.layoutDirection
    );

    // Map 'horizontal'/'vertical' to 'row'/'column'
    const flexDirection =
      container.layoutDirection === "horizontal" ? "row" : "column";

    const containerStyle: React.CSSProperties = {
      display: "flex",
      flexDirection: flexDirection,
      ...getSizeStyle(container.layoutDirection, container.size),
      border:
        showBorders && !isRootContainer
          ? selectedContainerId === container.id
            ? "1px dashed #3182ce" // Blue border for selected container
            : "1px dashed #ccc"
          : "none",
      transition: "background-color 0.3s, border-color 0.3s",
      backgroundColor:
        draggedOver === containerKeypath
          ? "rgba(0, 0, 255, 0.1)"
          : "transparent",
      width: isRootContainer ? "100%" : undefined,
      height: isRootContainer ? "100%" : undefined,
      padding: "8px",
      borderRadius: "4px",
      overflow: container.isScrollable ? "auto" : "hidden",
    };

    return (
      <div
        key={container.id}
        style={containerStyle}
        onClick={(e) => {
          e.stopPropagation();
          if (!isRootContainer) {
            setSelectedContainerId(container.id);
            setSelectedComponentIds([]);
            setSelectedContainerKeypath(containerKeypath);
          }
        }}
        onDragOver={(e) => {
          e.preventDefault();
          setDraggedOver(containerKeypath);
        }}
        onDragLeave={() => setDraggedOver(null)}
        onDrop={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setDraggedOver(null);
          const componentId = e.dataTransfer.getData("componentId");
          const blueprintName = e.dataTransfer.getData("blueprintName");
          const blueprint = blueprintName
            ? availableComponentBlueprints.find(
                (bp) => bp.name === blueprintName
              )
            : undefined;
          handleDrop(containerKeypath, componentId || null, blueprint);
        }}
        className={`cursor-pointer ${
          selectedContainerKeypath === containerKeypath
            ? "ring-2 ring-blue-500"
            : ""
        }`}
      >
        {container.components &&
          container.components.map((component, index) => (
            <div
              key={component.id}
              draggable
              onDragStart={(e) => {
                e.dataTransfer.setData("componentId", component.id);
                e.dataTransfer.setData(
                  "blueprintName",
                  component.blueprintName
                );
              }}
              onClick={(e: React.MouseEvent) => {
                e.stopPropagation();
                handleComponentSelect(component.id, containerKeypath);
                setSelectedContainerKeypath(null);
              }}
              className={`cursor-pointer relative mb-4`}
              style={{
                alignSelf: "flex-start",
                width: "100%",
              }}
            >
              {selectedComponentIds.includes(component.id) && (
                <>
                  <Box
                    position="absolute"
                    bottom="-24px"
                    left="0"
                    bg="blue.500"
                    color="white"
                    fontSize="xs"
                    px={2}
                    py={1}
                    borderRadius="md"
                    zIndex={10}
                  >
                    <Text fontWeight="bold">{component.blueprintName}</Text>
                  </Box>

                  <Box position="absolute" bottom="-24px" right="0" zIndex={10}>
                    <ButtonGroup size="xs" isAttached>
                      <IconButton
                        aria-label="Move component up"
                        icon={<ChevronUpIcon />}
                        isDisabled={index === 0}
                        onClick={(e: React.MouseEvent) => {
                          e.stopPropagation();
                          moveComponentUp(containerKeypath, index);
                        }}
                        bg="blue.500"
                        color="black"
                        _hover={{ bg: "blue.600" }}
                        _active={{ bg: "blue.700" }}
                        fontSize="1.2em"
                      />
                      <IconButton
                        aria-label="Move component down"
                        icon={<ChevronDownIcon />}
                        isDisabled={index === container.components.length - 1}
                        onClick={(e: React.MouseEvent) => {
                          e.stopPropagation();
                          moveComponentDown(containerKeypath, index);
                        }}
                        bg="blue.500"
                        color="black"
                        _hover={{ bg: "blue.600" }}
                        _active={{ bg: "blue.700" }}
                        fontSize="1.2em"
                      />
                    </ButtonGroup>
                  </Box>
                </>
              )}

              <div
                className={`${
                  selectedComponentIds.includes(component.id)
                    ? "ring-2 ring-blue-500"
                    : ""
                }`}
              >
                <ViewgraphComponentInstance
                  componentInstance={component}
                  isSelected={selectedComponentIds.includes(component.id)}
                />
              </div>
            </div>
          ))}
        {container.subcontainers.map((subcontainer) =>
          renderContainer(
            `${containerKeypath}/subcontainers/id:${subcontainer.id}`,
            container.layoutDirection
          )
        )}
      </div>
    );
  };

  const getComponentById = (componentId: string) => {
    const componentKeypath = findComponentKeypath(
      viewgraph.containerStructure,

      componentId,

      `${keypath}/containerStructure`
    );

    if (componentKeypath) {
      return getFragment(componentKeypath) as ComponentInstance;
    }

    return null;
  };

  const findComponentKeypath = (
    container: ContainerStructure,

    componentId: string,

    currentKeypath: string
  ): string | null => {
    if (
      container.components &&
      container.components.some((c) => c.id === componentId)
    ) {
      return `${currentKeypath}/components/id:${componentId}`;
    }

    for (const subcontainer of container.subcontainers) {
      const result = findComponentKeypath(
        subcontainer,

        componentId,

        `${currentKeypath}/subcontainers/id:${subcontainer.id}`
      );

      if (result) return result;
    }

    return null;
  };

  const handlePropertyChange = (propertyName: string, newValue: any) => {
    if (selectedComponentIds.length > 0) {
      selectedComponentIds.forEach((componentId) => {
        const componentKeypath = findComponentKeypath(
          viewgraph.containerStructure,

          componentId,

          `${keypath}/containerStructure`
        );

        if (componentKeypath) {
          const component = getFragment(componentKeypath) as ComponentInstance;

          const updatedComponent = {
            ...component,

            propertiesBindings: {
              ...component.propertiesBindings,

              [propertyName]: newValue,
            },
          };

          setFragment(componentKeypath, updatedComponent);
        }
      });
    }
  };

  const handleLayoutChange = (newLayout: ContainerStructure) => {
    setFragment(`${keypath}/containerStructure`, newLayout);
    setIsLayoutModalOpen(false);
    // Force a re-render
    setEditorMode((prevMode) =>
      prevMode === "layout" ? "properties" : "layout"
    );
  };

  const findComponentById = (
    container: ContainerStructure,

    componentId: string
  ): ComponentInstance | null => {
    for (const component of container.components || []) {
      if (component.id === componentId) {
        return component;
      }
    }

    for (const subcontainer of container.subcontainers) {
      const found = findComponentById(subcontainer, componentId);

      if (found) {
        return found;
      }
    }

    return null;
  };

  const handleDeleteComponents = () => {
    selectedComponentIds.forEach((componentId) => {
      const containerKeypath = findContainerKeypathForComponent(
        viewgraph.containerStructure,
        componentId,
        `${keypath}/containerStructure`
      );
      if (containerKeypath) {
        removeRecordFromCollectionFragment(
          `${containerKeypath}/components`,
          componentId
        );
      }
    });
    setSelectedComponentIds([]);
  };

  const handleToggleRootLayoutDirection = () => {
    const rootContainerKeypath = `${keypath}/containerStructure`;
    const rootContainer = getFragment(
      rootContainerKeypath
    ) as ContainerStructure;
    const newLayoutDirection =
      rootContainer.layoutDirection === "horizontal"
        ? "vertical"
        : "horizontal";

    // Update the entire viewgraph structure
    const updatedViewgraph = {
      ...viewgraph,
      containerStructure: {
        ...rootContainer,
        layoutDirection: newLayoutDirection,
      },
    };

    setFragment(keypath, updatedViewgraph);
  };

  const handleCopyBlueprintName = useCallback(() => {
    if (selectedComponentIds.length === 1) {
      const componentId = selectedComponentIds[0];
      const componentKeypath = findComponentKeypath(
        viewgraph.containerStructure,
        componentId,
        `${keypath}/containerStructure`
      );
      if (componentKeypath) {
        const component = getFragment(componentKeypath) as ComponentInstance;
        setBlueprintNameBuffer(component.blueprintName);
      }
    }
  }, [selectedComponentIds, viewgraph, keypath, getFragment]);

  const handlePasteComponent = useCallback(() => {
    if (blueprintNameBuffer && selectedContainerId) {
      handleAddComponent(blueprintNameBuffer);
    }
  }, [blueprintNameBuffer, selectedContainerId, handleAddComponent]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      const isMac = navigator.platform.toUpperCase().indexOf("MAC") >= 0;
      const isCopyShortcut = isMac
        ? event.metaKey && event.key === "c"
        : event.ctrlKey && event.key === "c";
      const isPasteShortcut = isMac
        ? event.metaKey && event.key === "v"
        : event.ctrlKey && event.key === "v";
      const isShortcutPressed = isMac
        ? event.metaKey && event.key === "e"
        : event.ctrlKey && event.key === "e";

      if (
        isShortcutPressed &&
        selectedContainerId &&
        selectedContainerId !== viewgraph.containerStructure.id
      ) {
        onOpen();
      }

      if (event.metaKey && event.key === "Backspace") {
        event.preventDefault();
        if (selectedComponentIds.length > 0) {
          handleDeleteComponents();
        }
      }

      if (isCopyShortcut) {
        event.preventDefault();
        handleCopyBlueprintName();
      }

      if (isPasteShortcut) {
        event.preventDefault();
        handlePasteComponent();
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [
    selectedContainerId,
    viewgraph.containerStructure.id,
    onOpen,
    selectedComponentIds,
    handleDeleteComponents,
    handleCopyBlueprintName,
    handlePasteComponent,
  ]);

  const selectedComponent =
    selectedComponentIds.length > 0
      ? getComponentById(selectedComponentIds[0])
      : null;

  const handleContainerSelect = (containerId: string) => {
    const containerKeypath = findContainerKeypathById(
      viewgraph.containerStructure,
      containerId,
      `${keypath}/containerStructure`
    );
    if (containerKeypath) {
      setSelectedContainerId(containerId);
      setSelectedContainerKeypath(containerKeypath);
      setSelectedComponentIds([]);
    }
  };

  return (
    <div className="flex h-full overflow-hidden" ref={editorRef}>
      <div className="w-110 h-full overflow-y-auto border-r border-gray-300 flex flex-col">
        <HStack spacing={1} p={1}>
          <Button
            onClick={onOpen}
            colorScheme="blue"
            size="xs" // Ensure the button is the same size
            isDisabled={
              !selectedContainerId ||
              selectedContainerId === viewgraph.containerStructure.id
            }
          >
            Add Component
          </Button>
        </HStack>
        <ComponentTree
          structure={viewgraph.containerStructure}
          selectedComponentIds={selectedComponentIds}
          setSelectedComponentIds={setSelectedComponentIds}
          selectedContainerId={selectedContainerId}
          onSelectComponent={(componentId: string) => {
            const containerKeypath = findContainerKeypathForComponent(
              viewgraph.containerStructure,
              componentId,
              `${keypath}/containerStructure`
            );
            if (containerKeypath) {
              handleComponentSelect(componentId, containerKeypath);
            }
          }}
          onSelectContainer={handleContainerSelect}
          onMoveComponent={handleMoveComponentInTree}
          availableComponentBlueprints={availableComponentBlueprints}
          onToggleRootLayoutDirection={handleToggleRootLayoutDirection}
          onDeleteComponent={handleDeleteComponents}
        />
      </div>

      <div className="flex-grow flex flex-col overflow-hidden">
        <div className="p-2 border-b border-gray-300 flex justify-between items-center">
          <Switch
            isChecked={showBorders}
            onChange={(e) => setShowBorders(e.target.checked)}
            colorScheme="blue"
          >
            Show container borders
          </Switch>

          {viewgraph.containerStructure &&
            viewgraph.containerStructure.isLayoutApplied && (
              <Button
                leftIcon={<EditIcon />}
                onClick={() => setIsLayoutModalOpen(true)}
                size="sm"
                colorScheme="teal"
                mr={2}
              >
                Edit Layout
              </Button>
            )}
        </div>

        <div className="flex-grow relative overflow-auto p-4">
          {viewgraph.containerStructure &&
          viewgraph.containerStructure.isLayoutApplied ? (
            <div
              className="border-2 border-gray-300 rounded-lg shadow-lg overflow-hidden"
              style={{
                width: "100%",
                height: "100%",
              }}
            >
              {renderContainer(`${keypath}/containerStructure`, "vertical")}
            </div>
          ) : (
            <div className="flex items-center justify-center h-full">
              <div className="text-center">
                <p className="mb-4 text-gray-600">No layout applied yet.</p>
                <Button
                  colorScheme="blue"
                  onClick={() => setIsLayoutModalOpen(true)}
                >
                  Edit Layout
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="w-96 flex-shrink-0 border-l border-gray-300 flex flex-col h-full">
        {selectedComponent ? (
          <>
            <div className="mb-4 flex justify-between items-center">
              <div className="mb-4 mt-5 ml-2 flex justify-between items-center">
                <ButtonGroup isAttached variant="outline">
                  <Button
                    onClick={() => setEditorMode("properties")}
                    colorScheme={editorMode === "properties" ? "blue" : "gray"}
                    variant={editorMode === "properties" ? "solid" : "outline"}
                  >
                    Properties
                  </Button>
                  <Button
                    onClick={() => setEditorMode("layout")}
                    colorScheme={editorMode === "layout" ? "blue" : "gray"}
                    variant={editorMode === "layout" ? "solid" : "outline"}
                  >
                    Layout
                  </Button>
                  <Button
                    onClick={() => setEditorMode("style")}
                    colorScheme={editorMode === "style" ? "blue" : "gray"}
                    variant={editorMode === "style" ? "solid" : "outline"}
                  >
                    Style
                  </Button>
                </ButtonGroup>
              </div>
              <IconButton
                aria-label="Delete component"
                icon={<DeleteIcon />}
                colorScheme="red"
                size="sm"
                onClick={() => {
                  console.log(
                    "Deleting components at keypath: ",
                    selectedContainerKeypath
                  );
                  handleDeleteComponents();
                }}
              />
            </div>

            {editorMode === "properties" ? (
              <ComponentPropertiesPanel
                selectedComponentId={selectedComponent.id}
                selectedComponentKeypath={findComponentKeypath(
                  viewgraph.containerStructure,
                  selectedComponent.id,
                  `${keypath}/containerStructure`
                )}
                availableComponentBlueprints={availableComponentBlueprints}
                onDeleteComponent={handleDeleteComponents}
              />
            ) : editorMode === "layout" ? (
              <ComponentLayoutEditor
                selectedComponent={selectedComponent}
                blueprints={availableComponentBlueprints.reduce(
                  (acc, blueprint) => {
                    acc[blueprint.name] = blueprint;
                    return acc;
                  },
                  {} as Record<string, any>
                )}
                onLayoutChange={(
                  componentId: string,
                  updatedLayout: Record<string, string>
                ) => {
                  // Implement the logic to update the layout for a specific component
                  console.log(
                    "Updating layout for component:",
                    componentId,
                    updatedLayout
                  );
                }}
              />
            ) : (
              <div className="p-4 text-center text-gray-500">
                Styles are not yet supported.
              </div>
            )}
          </>
        ) : selectedContainerKeypath ? (
          renderContainerProperties()
        ) : (
          <div className="p-4 text-center text-gray-500">
            Select a component or container to view its properties
          </div>
        )}
      </div>

      <NoComponentsModal />

      <Modal
        isOpen={isLayoutModalOpen}
        onClose={() => setIsLayoutModalOpen(false)}
        size="xl"
      >
        <ModalOverlay />
        <ModalContent maxWidth="90vw" maxHeight="90vh">
          <ModalHeader>Layout Editor</ModalHeader>
          <ModalBody overflow="auto">
            <LayoutModeEditor
              keypath={keypath}
              onLayoutChange={handleLayoutChange}
            />
          </ModalBody>
          <ModalFooter>
            <Button onClick={() => setIsLayoutModalOpen(false)}>Close</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <ComponentLibraryModal
        isOpen={isOpen}
        onClose={onClose}
        componentBlueprints={availableComponentBlueprints}
        onAddComponent={handleAddComponent}
      />
    </div>
  );
};

const findContainerKeypathForComponent = (
  container: ContainerStructure,
  componentId: string,
  currentKeypath: string
): string | null => {
  if (
    container.components &&
    container.components.some((c) => c.id === componentId)
  ) {
    return currentKeypath;
  }

  for (const subcontainer of container.subcontainers) {
    const result = findContainerKeypathForComponent(
      subcontainer,
      componentId,
      `${currentKeypath}/subcontainers/id:${subcontainer.id}`
    );
    if (result) return result;
  }

  return null;
};

const generateUniqueId = () => {
  return Math.random().toString(36).substr(2, 9);
};

const findContainerKeypathById = (
  container: ContainerStructure,
  containerId: string,
  currentKeypath: string
): string | null => {
  if (container.id === containerId) {
    return currentKeypath;
  }

  for (const subcontainer of container.subcontainers) {
    const result = findContainerKeypathById(
      subcontainer,
      containerId,
      `${currentKeypath}/subcontainers/id:${subcontainer.id}`
    );
    if (result) return result;
  }

  return null;
};

export default ComponentModeEditor;
