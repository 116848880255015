import { create } from "zustand";
import { produce } from "immer";
import { z } from "zod";
import { v4 as uuidv4 } from "uuid";
import debounce from "lodash/debounce";
import { traverseKeypath, applyPatch, Operation } from "../utils/bjsonPatch";
import { humanizeKey } from "../utils/stringUtils";
import { railsApiCall } from "../utils/railsApiCall";
import { generateUniqueId } from "../utils/idUtils";
import type { TypedAppDescriptor } from "../schemas/appDescriptorSchema";
import {
  Page,
  ContainerStructure,
  PageMethod,
  PageGroup,
} from "../schemas/essentials/pagesSchema";
import { DescriptorRecord } from "../schemas/descriptorUtils";
import { seedData } from "../data/seedData";
import { useEffect } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { ComponentBlueprint } from "../schemas/userInterface/componentsSchema";
import {
  getEnrichedPageDescriptor,
  EnrichedPage,
} from "../schemas/essentials/pagesSchema";
import { DataModel } from "../schemas/essentials/dataModelsSchema";
import { applicationAssetsSchema } from "../schemas/userInterface/applicationAssetsSchema";

interface BreadcrumbItem {
  keypath: string;
  displayName: string;
  href: string;
  isCurrentPage: boolean;
  isCollection: boolean;
  isRecord: boolean;
  isCategory: boolean;
}

const AUTOSAVE_DELAY = 5;

interface DomIdOption {
  id: string;
  name: string;
  description: string;
  componentId: string;
}

interface PageRoute {
  httpMethod: string;
  path: string;
}

interface PageParameter {
  name: string;
  dataType: string;
  description: string;
}

interface AppDescriptorState {
  appDescriptor: TypedAppDescriptor;
  pendingPatches: Operation[];
  applyPatch: (patch: Operation) => void;
  getFragment: (keypath: string) => unknown;
  setFragment: (keypath: string, value: unknown) => void;
  addRecordToCollectionFragment: (
    collectionKeypath: string,
    newItem: DescriptorRecord<any>
  ) => string;
  removeRecordFromCollectionFragment: (
    collectionKeypath: string,
    id: string
  ) => void;
  updateRecordInCollectionFragment: (
    collectionKeypath: string,
    id: string,
    updatedFields: Partial<DescriptorRecord<any>>
  ) => void;
  projectId: string;
  projectName: string;
  setProjectName: (name: string) => void;
  isSaving: boolean;
  saveError: string | null;
  lastSavedAt: Date | null;
  saveAppDescriptor: () => void;
  debouncedSave: () => void;
  seedDescriptor: () => void;
  getPageVariables: (keypath: string) => string[];
  getBreadcrumbItems: (pathname: string) => BreadcrumbItem[];
  fetchDescriptor: (projectId: string) => Promise<TypedAppDescriptor>;
  isInitialLoad: boolean;
  originalDescriptor: TypedAppDescriptor | null;
  applyPatches: (patches: Operation[]) => void;
  undoStack: Operation[][];
  redoStack: Operation[][];
  canUndo: boolean;
  canRedo: boolean;
  undo: () => void;
  redo: () => void;
  duplicateRecord: (
    collectionKeypath: string,
    id: string,
    overrides?: Partial<DescriptorRecord<any>>
  ) => void;
  subscribe: (listener: () => void) => () => void;
  getViewgraphDomIds: (pageId: string) => DomIdOption[];
  getDeclaredPageVariables: (pageId: string) => string[];
  getPageMethods: (pageId: string) => PageMethod[];
  getPageMethodName: (pageId: string, methodId: string) => string | undefined;
  getParentFunctionParameters: (keypath: string) => string[];
  getComponentBlueprint: (
    blueprintId: string
  ) => ComponentBlueprint | undefined;
  getComponentBlueprints: () => ComponentBlueprint[];
  getComponentBlueprintsFromDescriptor: () => ComponentBlueprint[];
  coreComponentBlueprints: ComponentBlueprint[];
  fetchCoreComponentBlueprints: () => Promise<void>;
  getCoreComponentBlueprint: (
    blueprintId: string
  ) => ComponentBlueprint | undefined;
  initializeAppDescriptor: () => Promise<void>;
  availableComponentBlueprints: ComponentBlueprint[];
  updateAvailableComponentBlueprints: () => void;
  showSaveToast: (message: string) => void;
  getPage: (pageId: string) => EnrichedPage | null;
  getPageByKeypath: (keypath: string) => EnrichedPage | null;
  getPageGroupByKeypath: (keypath: string) => PageGroup | null;
  getModelByKeypath: (keypath: string) => DataModel | null;
  isLoading: boolean;
}

const initialAppDescriptor: TypedAppDescriptor = {
  userInterface: {
    applicationAssets: [],
  },
};

export const useAppDescriptorStore = create<AppDescriptorState>((set, get) => ({
  appDescriptor: initialAppDescriptor,
  pendingPatches: [],
  projectId: "",
  projectName: "",
  setProjectName: (name: string) => set({ projectName: name }),
  isSaving: false,
  saveError: null,
  lastSavedAt: null,
  isLoading: true,

  applyPatch: (patch: Operation) =>
    set(
      produce((draft) => {
        applyPatch(draft.appDescriptor, patch);
        console.log("Patch:", patch);
        draft.pendingPatches.push(patch);
        draft.undoStack.push([patch]);
        draft.redoStack = [];
        draft.canUndo = true;
        draft.canRedo = false;
        get().debouncedSave();
      })
    ),

  applyPatches: (patches: Operation[]) =>
    set(
      produce((draft) => {
        patches.forEach((patch) => {
          applyPatch(draft.appDescriptor, patch);
          draft.pendingPatches.push(patch);
        });
        draft.undoStack.push(patches);
        draft.redoStack = [];
        draft.canUndo = true;
        draft.canRedo = false;
        get().debouncedSave();
      })
    ),

  getFragment: (keypath: string): any => {
    if (get().isLoading) {
      console.warn(
        `Attempted to access ${keypath} while app descriptor is still loading`
      );
      return null;
    }
    try {
      const result = traverseKeypath(get().appDescriptor, keypath);
      return result;
    } catch (error) {
      console.warn(`Error getting fragment at keypath: ${keypath}`, error);
      return null;
    }
  },

  setFragment: (keypath: string, value: unknown) => {
    console.log("Setting fragment at keypath:", keypath, "with value:", value);
    get().applyPatch({ op: "replace", path: keypath, value });
  },

  addRecordToCollectionFragment: <T extends z.ZodRawShape>(
    collectionKeypath: string,
    newItem: DescriptorRecord<T>
  ) => {
    const newItemId = newItem.id || generateUniqueId();
    const newItemWithId = { ...newItem, id: newItemId };
    console.log(
      "Adding record to collection:",
      collectionKeypath,
      newItemWithId
    );
    const collection = get().getFragment(collectionKeypath);
    console.log("Collection:", collection);
    if (!Array.isArray(collection)) {
      console.log("Replacing collection with new item");
      get().applyPatch({
        op: "replace",
        path: collectionKeypath,
        value: [newItemWithId],
      });
    } else {
      console.log("Adding new item to collection");
      get().applyPatch({
        op: "addRecord",
        path: collectionKeypath,
        value: newItemWithId,
        id: newItemId,
      });
    }
    return newItemId;
  },

  removeRecordFromCollectionFragment: (
    collectionKeypath: string,
    id: string
  ) => {
    get().applyPatch({ op: "removeRecord", path: collectionKeypath, id });
  },

  updateRecordInCollectionFragment: (
    collectionKeypath: string,
    id: string,
    updatedFields: Partial<DescriptorRecord<any>>
  ) => {
    get().applyPatch({
      op: "updateRecord",
      path: collectionKeypath,
      id: id,
      value: updatedFields,
    });
  },

  seedDescriptor: () => {
    const patch = { op: "replace", path: "/", value: seedData };
    set(
      produce((draft) => {
        draft.appDescriptor = seedData;
        draft.originalDescriptor = seedData;
        draft.pendingPatches = [patch];
      })
    );
    get().saveAppDescriptor();
  },

  getBreadcrumbItems: (pathname: string): BreadcrumbItem[] => {
    const cleanPath = pathname.replace(/^\//, "");
    const parts = cleanPath.split("/").filter(Boolean);
    let currentPath = "/";

    return parts.map((part, index) => {
      currentPath += (index > 0 ? "/" : "") + part;
      const fragment = get().getFragment(currentPath);

      let isCollection = Array.isArray(fragment);
      let isRecord =
        typeof fragment === "object" && fragment !== null && "id" in fragment;
      let isCategory = !isCollection && !isRecord;

      // Special handling for 'dataModels' and other known collections
      if (part === "dataModels" || part === "pages" || part === "services") {
        isCollection = true;
        isCategory = false;
      }

      // Handle the case when the fragment is undefined (e.g., for 'id:1')
      if (fragment === undefined && part.startsWith("id:")) {
        isRecord = true;
        isCategory = false;
      }

      let displayName;
      if (isRecord) {
        // If it's a record, try to get the name from the fragment
        displayName = (fragment as any)?.name || humanizeKey(part);
      } else if (part.startsWith("id:")) {
        // If it's an id but we couldn't get the fragment, use the previous part's fragment to get the name
        const parentFragment = get().getFragment(
          currentPath.split("/").slice(0, -1).join("/")
        );
        const record = (parentFragment as any[])?.find(
          (item) => item.id === part.slice(3)
        );
        displayName = record?.name || humanizeKey(part);
      } else {
        displayName = humanizeKey(part);
      }

      return {
        keypath: currentPath,
        displayName,
        href: `${currentPath}`,
        isCurrentPage: index === parts.length - 1,
        isCollection,
        isRecord,
        isCategory,
      };
    });
  },

  saveAppDescriptor: async () => {
    const {
      projectId,
      appDescriptor,
      originalDescriptor,
      pendingPatches,
      showSaveToast,
    } = get();
    if (!projectId || !originalDescriptor) {
      set({
        saveError:
          "Cannot save: Project ID is not set or original descriptor is missing",
      });
      return;
    }

    set({ isSaving: true });

    try {
      const result = await railsApiCall({
        method: "PATCH",
        endpoint: `/projects/${projectId}/app_descriptor`,
        body: { patches: pendingPatches },
      });

      if (result.ok) {
        console.log("App descriptor saved successfully");
        showSaveToast("App descriptor saved successfully");
        set({
          pendingPatches: [],
          originalDescriptor: appDescriptor,
          saveError: null,
          lastSavedAt: new Date(),
          isSaving: false,
        });
      } else {
        throw new Error("Failed to save app descriptor");
      }
    } catch (error) {
      const errorMessage =
        error instanceof Error ? error.message : "An unknown error occurred";
      set({
        saveError: errorMessage,
        isSaving: false,
      });
      showSaveToast(`Error: ${errorMessage}`);
    }
  },

  debouncedSave: debounce(() => {
    get().saveAppDescriptor();
  }, AUTOSAVE_DELAY),

  fetchDescriptor: async (): Promise<TypedAppDescriptor> => {
    const projectId = window.location.pathname.split("/")[2]; // Assumes URL structure like /projects/:projectId/...
    try {
      const data = await railsApiCall<TypedAppDescriptor>({
        method: "GET",
        endpoint: `/projects/${projectId}/app_descriptor`,
      });
      set({ appDescriptor: data.data, projectId, projectName: data.data.name });
      return data.data;
    } catch (error) {
      console.error("Error fetching descriptor:", error);
      throw error;
    }
  },
  getPageVariables: (keypath: string) => {
    const state = get();
    const pageBaseKeypath = keypath.split("/userInterface/")[0];

    const initializationLogicFlowgraph = state.getFragment(
      `${pageBaseKeypath}/initializationLogic/flowgraph`
    );

    console.log("initializationLogicFlowgraph", initializationLogicFlowgraph);
    // Find initializationLogic sibling

    if (
      !initializationLogicFlowgraph ||
      !Array.isArray(initializationLogicFlowgraph)
    ) {
      return [];
    }

    // Find declarePageVariable directives
    const pageVariables = initializationLogicFlowgraph
      .filter(
        (directive: any) => directive.directiveType === "declarePageVariable"
      )
      .map((directive: any) => directive.config.name);

    return pageVariables;
  },

  isInitialLoad: true,
  originalDescriptor: null,

  undoStack: [],
  redoStack: [],
  canUndo: false,
  canRedo: false,

  undo: () => {
    const { undoStack, redoStack, appDescriptor } = get();
    if (undoStack.length > 0) {
      const lastPatch = undoStack[undoStack.length - 1];
      const reversedPatch = lastPatch.map((operation) => ({
        ...operation,
        op:
          operation.op === "add"
            ? "remove"
            : operation.op === "remove"
            ? "add"
            : operation.op,
        value:
          operation.op === "replace"
            ? get().getFragment(operation.path)
            : operation.value,
      }));
      let newDescriptor = { ...appDescriptor };
      reversedPatch.forEach((operation) => {
        applyPatch(newDescriptor, operation as Operation);
      });
      set({
        appDescriptor: newDescriptor,
        undoStack: undoStack.slice(0, -1),
        redoStack: [...redoStack, lastPatch],
        canUndo: undoStack.length > 1,
        canRedo: true,
      });
    }
  },

  redo: () => {
    const { undoStack, redoStack, appDescriptor } = get();
    if (redoStack.length > 0) {
      const lastPatch = redoStack[redoStack.length - 1];
      let newDescriptor = { ...appDescriptor };
      lastPatch.forEach((operation) => {
        applyPatch(newDescriptor, operation);
      });
      set({
        appDescriptor: newDescriptor,
        undoStack: [...undoStack, lastPatch],
        redoStack: redoStack.slice(0, -1),
        canUndo: true,
        canRedo: redoStack.length > 1,
      });
    }
  },

  duplicateRecord: (
    collectionKeypath: string,
    id: string,
    overrides: Partial<DescriptorRecord<any>> = {}
  ) => {
    const collection = get().getFragment(
      collectionKeypath
    ) as DescriptorRecord<any>[];
    const recordToDuplicate = collection.find((item) => item.id === id);

    if (recordToDuplicate) {
      const newRecord = {
        ...recordToDuplicate,
        id: generateUniqueId(),
        ...overrides,
      };

      get().applyPatch({
        op: "addRecord",
        path: collectionKeypath,
        value: newRecord,
        id: newRecord.id,
      });
    } else {
      console.error(`Record with id ${id} not found in ${collectionKeypath}`);
    }
  },
  subscribe: (listener) => {
    const unsubscribe = get().subscribe(listener);
    return unsubscribe;
  },
  getViewgraphDomIds: (pageId: string): DomIdOption[] => {
    const viewgraph = get().getPage(pageId)?.userInterface.viewgraph;

    if (!viewgraph || !viewgraph.containerStructure) return [];

    const extractDomIds = (container: ContainerStructure): DomIdOption[] => {
      let domIds: DomIdOption[] = [];

      // Extract DOM IDs from components
      if (Array.isArray(container.components)) {
        container.components.forEach((component) => {
          if (component.domId) {
            domIds.push({
              id: component.domId,
              name: component.name || component.blueprintName,
              description: "Component root DOM ID",
              componentId: component.id || "",
            });
          }

          // Extract internal DOM IDs
          if (Array.isArray(component.internalDomIds)) {
            component.internalDomIds.forEach((internalDomId) => {
              domIds.push({
                id: internalDomId.id,
                name: internalDomId.name || internalDomId.id,
                description: internalDomId.description || "",
                componentId: component.id || "",
              });
            });
          }
        });
      }

      // Recursively extract DOM IDs from subcontainers
      if (Array.isArray(container.subcontainers)) {
        container.subcontainers.forEach((subcontainer) => {
          domIds = [...domIds, ...extractDomIds(subcontainer)];
        });
      }

      return domIds;
    };

    return extractDomIds(viewgraph.containerStructure);
  },
  getParentFunctionParameters: (keypath: string) => {
    const parts = keypath.split("/");
    let currentPath = "";

    for (let i = parts.length - 1; i >= 0; i--) {
      currentPath = parts.slice(0, i).join("/");
      const fragment = get().getFragment(currentPath);

      if (
        fragment &&
        typeof fragment === "object" &&
        "parameters" in fragment
      ) {
        return (fragment.parameters as any[]).map((param) => param.name);
      }
    }

    return [];
  },
  getComponentBlueprint: (blueprintId: string) => {
    return get()
      .getComponentBlueprints()
      .find((blueprint) => blueprint.id === blueprintId);
  },
  getComponentBlueprints: () => {
    get().fetchCoreComponentBlueprints();
    const userBlueprints = get().getComponentBlueprintsFromDescriptor() || [];
    return [...userBlueprints, ...get().coreComponentBlueprints];
  },
  getComponentBlueprintsFromDescriptor: () => {
    const userInterface = get().appDescriptor.userInterface;
    if (!userInterface || !userInterface.componentBlueprints) {
      return [];
    }
    return userInterface.componentBlueprints;
  },
  coreComponentBlueprints: [],
  fetchCoreComponentBlueprints: async () => {
    if (get().coreComponentBlueprints.length > 0) {
      return; // Core blueprints are already loaded
    }
    try {
      const { data, ok } = await railsApiCall<{
        component_blueprints: ComponentBlueprint[];
      }>({
        method: "GET",
        endpoint: "/core_component_blueprints",
      });
      if (ok) {
        set({ coreComponentBlueprints: data.component_blueprints });
        console.log("coreComponentBlueprints", get().coreComponentBlueprints);
      } else {
        throw new Error("Failed to fetch core component blueprints");
      }
    } catch (error) {
      console.error("Error fetching core component blueprints:", error);
    }
  },
  getCoreComponentBlueprint: (blueprintId: string) => {
    return get().coreComponentBlueprints.find(
      (blueprint) => blueprint.id === blueprintId
    );
  },
  initializeAppDescriptor: async () => {
    set({ isLoading: true });
    try {
      const descriptor = await get().fetchDescriptor();
      set({
        appDescriptor: descriptor,
        originalDescriptor: descriptor,
        isInitialLoad: false,
        isLoading: false,
      });
      await get().fetchCoreComponentBlueprints();
      get().updateAvailableComponentBlueprints();
    } catch (error) {
      console.error("Error initializing app descriptor:", error);
      set({
        saveError: "Failed to initialize app descriptor",
        isLoading: false,
      });
    }
  },
  availableComponentBlueprints: [],
  updateAvailableComponentBlueprints: () => {
    const descriptorBlueprints = get().getComponentBlueprintsFromDescriptor();
    const coreBlueprints = get().coreComponentBlueprints;
    set({
      availableComponentBlueprints: [
        ...descriptorBlueprints,
        ...coreBlueprints,
      ],
    });
  },
  showSaveToast: (message: string) => {
    // This will be implemented in the ToastProvider
  },
  getPage: (pageId: string) => {
    const appDescriptor = get().appDescriptor;
    return getEnrichedPageDescriptor(pageId, appDescriptor);
  },
  getPageByKeypath: (keypath: string) => {
    const pageId = keypath.split("/pages/id:")[1].split("/")[0];
    return getEnrichedPageDescriptor(pageId, get().appDescriptor);
  },
  getPageMethods: (pageId: string): PageMethod[] => {
    const page = get().getPage(pageId);
    return page?.pageMethods || [];
  },
  getPageGroupByKeypath: (keypath: string) => {
    const pageGroupId = keypath.split("/pageGroups/id:")[1].split("/")[0];
    return (get().appDescriptor?.essentials?.pageGroups as PageGroup[]).find(
      (group: PageGroup) => group.id === pageGroupId
    );
  },
  getModelByKeypath: (keypath: string) => {
    const modelId = keypath.split("/dataModels/id:")[1].split("/")[0];
    return (get().appDescriptor?.essentials?.dataModels as DataModel[]).find(
      (model: DataModel) => model.id === modelId
    );
  },
}));

export const useUndoRedoHotkeys = () => {
  const { undo, redo, canUndo, canRedo } = useAppDescriptorStore();

  useHotkeys(
    "ctrl+z, cmd+z",
    (event) => {
      event.preventDefault();
      if (canUndo) undo();
    },
    { enableOnFormTags: true },
    [canUndo, undo]
  );

  useHotkeys(
    "ctrl+shift+z, cmd+shift+z",
    (event) => {
      event.preventDefault();
      if (canRedo) redo();
    },
    { enableOnFormTags: true },
    [canRedo, redo]
  );

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if ((event.ctrlKey || event.metaKey) && event.key === "y") {
        event.preventDefault();
        if (canRedo) redo();
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [canRedo, redo]);
};
